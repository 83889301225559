import { useNavigate } from "react-router-dom";
import { Button, message } from "antd";
import logo from "../../assets/logo/logo.png";
import bg from "../../assets/image/bg.png";
import "./index.css";
import { LogIn } from "@styled-icons/ionicons-outline/LogIn";
import { At } from "@styled-icons/bootstrap/At";
import MyInput from "../../components/input";
import { ArrowRightCircle } from "@styled-icons/bootstrap/ArrowRightCircle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setIsBatchDdn, setUserDetails } from "../../state/slice/appSlice";
import axios from "axios";
import MyButton from "../../components/buttons";
const HomePage = () => {
	const dispatch = useDispatch();
	const validUsers = [
		{ username: "ms30001", password: "ms30001" },
		{ username: "dg27025", password: "dg27025" },
		{ username: "aa20384", password: "aa20384" },
		{ username: "ah36437", password: "ah36437" },
		{ username: "ks23286", password: "ks23286" },
		{ username: "rc30003", password: "rc30003" },
		// { username: "ak8953", password: "ak8953" },
		{ username: "sj17516", password: "sj17516" },
		{ username: "sy24500", password: "sy24500" },
		{ username: "sj41366", password: "sj41366" },
		{ username: "nm41375", password: "nm41375" },
	];
	const navigate = useNavigate();
	const [isUsername, setIsUsername] = useState(true);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);
	const [loggedinUsername, setLoggedinUsername] = useState("");

	const submitResponse = async () => {
		const payload = {
			username,
			password,
		};
		axios
			.post(process.env.REACT_APP_API_URL + "/authorize", payload)
			.then((res) => {
				if (res.data.data.authorized === true) {
					dispatch(setUserDetails({ username: username }));
					dispatch(setIsBatchDdn(res.data.data.ddn_access));
					sessionStorage.setItem("username", username);
					sessionStorage.setItem(
						"isBatchDdn",
						res.data.data.ddn_access
					);
					setLoggedinUsername(username);
					navigate("active-agents");
				} else setError(true);
				setIsUsername(true);
				setUsername("");
				setPassword("");
			})
			.catch((e) => {
				message.error("Error trying to log in!");
			});
	};
	const handleSubmit = () => {
		if (isUsername) {
			setIsUsername(false);
			// if (validUsers.find((el) => el.username === username)) {
			// 	setIsUsername(false);
			// } else setError(true);
		} else {
			submitResponse();
			// if (
			// 	validUsers.find((el) => el.username === username)?.password ===
			// 	password
			// ) {
			// 	dispatch(setUserDetails({ username: username }));
			// 	sessionStorage.setItem("username", username);
			// 	navigate("active-agents");
			// } else setError(true);
		}
	};
	// const [localVar, setLocalVar] = useState<number>(store.sampleStateVal);
	// const dispatch = useDispatch();

	// useEffect(() => {
	// 	dispatch(setStateVals(localVar));
	// }, [localVar]);
	// useEffect(() => {
	// 	const intervalId = setInterval(() => {
	// 		setLocalVar((prevVal) => prevVal + 1);
	// 	}, 1000);
	// 	return () => clearInterval(intervalId);
	// }, []);
	useEffect(() => {
		const sessionUsername = sessionStorage.getItem("username");
		if (sessionUsername) setLoggedinUsername(sessionUsername);
		// const isBatchDdn = sessionStorage.getItem("isBatchDdn");
		// if(isBatchDdn) dispatch(setIsBatchDdn(isBatchDdn));
	}, []);
	return (
		<div className="containers">
			<div className="left bg-body-zs-dark-bg">
				<div className="w-20 h-20">
					<img src={logo} alt="" style={{ width: "100%" }} />
				</div>
				<div
					className="text-text-zs-orange"
					style={{ fontSize: "40px", fontWeight: 700 }}
				>
					Gen AI Agents Gallery
				</div>
				<div className="mt-8">
					<p
						className="text-white px-40"
						style={{ fontSize: "14px", fontWeight: 400 }}
					>
						Empower your business with AI-powered agents designed to streamline document management and data extraction. Transform raw data into actionable insights, driving faster, smarter decisions across all functions.
					</p>
				</div>
				<div className="mt-20">
					<p
						className="text-white"
						style={{ fontSize: "32px", fontWeight: 600 }}
					>
						Get started now
					</p>
				</div>
				{!loggedinUsername && (
					<div className="mt-6 text-text-zs-orange w-80">
						<div className="flex items-center justify-center gap-4">
							<div>{isUsername ? "Username" : "Password"}</div>
							<MyInput
								value={isUsername ? username : password}
								type={isUsername ? "text" : "password"}
								placeholder={
									isUsername ? "Username" : "Password"
								}
								onChange={(e) => {
									setError(false);
									isUsername
										? setUsername(e.target.value)
										: setPassword(e.target.value);
								}}
								onPressEnter={handleSubmit}
							/>
							<ArrowRightCircle
								size={32}
								onClick={handleSubmit}
								className="cursor-pointer"
							/>
						</div>

						<div className="text-xs italic text-red-600 h-4 text-center mt-2">
							{error && "Incorrect credentils"}
						</div>

						{/* <Button
						type="primary"
						className="button1"
						onClick={() => navigate("active-agents")}
					>
						<LogIn
							className="mr-1"
							size={22}
							style={{
								color: "#ffffff",
								marginBottom: "0.15rem",
							}}
						/>{" "}
						Login with ZS SSO
					</Button> */}
					</div>
				)}
				{loggedinUsername && (
					<div className="mt-6 text-text-zs-orange w-80">
						<div className="flex items-center justify-center gap-4">
							<MyButton
								type="primary"
								onClick={() => navigate("active-agents")}
							>
								Logged in as {loggedinUsername}
							</MyButton>
						</div>
					</div>
				)}
			</div>
			<div className="right">
				<img
					src={bg}
					alt=""
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
					}}
				/>
				<div className="overlay">
					<div className="bg-white ml-48 mr-48 p-8 overlayChild">
						{/* How many contracts I need to review today? Can you
						filter the contracts and show me ones that have a rebate
						greater than 5%? */}
						<div className="atIcon">
							<At
								size={42}
								style={{
									color: "#ffffff",
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default HomePage;
