import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage
import { appReduxSlice } from "./slice/appSlice";

// Redux Persist Configuration
const persistConfig = {
    key: "root",
    storage,
};

// Wrap Reducer with Persist
const persistedReducer = persistReducer(persistConfig, appReduxSlice.reducer);

export const store = configureStore({
    reducer: {
        app_reducer: persistedReducer,
    },
    devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
