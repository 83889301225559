import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../model/IAppState";
import { RootState } from "../store";
import { ISidebarOptions } from "../../model/ISidebarOptions";

const initialState: IAppState = {
    userDetails: {
        username: "",
    },
    selectedActiveAgent: {
        name: "",
        id: -1,
        icon: "",
    },
    selectedFeature: {
        name: "",
        id: -1,
        icon: "",
    },
    selectedContract: {
        name: "",
        id: -1, // No need for localStorage.getItem() (Redux Persist handles this)
    },
    sidebarOptions: [],
    batchDropdown: {},
    isBatchDdn: false,
};

export const appReduxSlice = createSlice({
    name: "appSlice",
    initialState,
    reducers: {
        setSelectedActiveAgent: (
            state,
            action: PayloadAction<{ name: string; id: number; icon?: string }>
        ) => {
            state.selectedActiveAgent = action.payload;
        },
        setSelectedFeature: (
            state,
            action: PayloadAction<{ name: string; id: number; icon?: string }>
        ) => {
            state.selectedFeature = action.payload;
        },
        setUserDetails: (state, action: PayloadAction<{ username: string }>) => {
            state.userDetails.username = action.payload.username;
        },
        setContractDetails: (
            state,
            action: PayloadAction<{ name: string; id: number }>
        ) => {
            state.selectedContract = action.payload;
        },
        setSidebarOptions: (state, action: PayloadAction<ISidebarOptions[]>) => {
            state.sidebarOptions = action.payload;
        },
        setBatchDropdown: (
            state,
            action: PayloadAction<{ options: { label: string; value: string }[]; defaultValue: string }>
        ) => {
            state.batchDropdown = action.payload;
        },
        setIsBatchDdn: (state, action: PayloadAction<boolean>) => {
            state.isBatchDdn = action.payload;
        },
    },
});

export const {
    setSelectedActiveAgent,
    setSelectedFeature,
    setUserDetails,
    setContractDetails,
    setSidebarOptions,
    setBatchDropdown,
    setIsBatchDdn,
} = appReduxSlice.actions;

export const selectStore = (state: RootState) => state.app_reducer;
