import React, { useEffect, useState, useRef } from "react";
import { Button, Typography, Row, Col, Divider, Spin } from 'antd';
import { useSelector } from "react-redux";
import axios from 'axios';
import AudioTranscriptSlider from '../../components/audioResponseSlider';
import EmailResponseSlider from '../../components/emailResponseSlider';
import QASection from '../../components/QASection';
import './index.css';
import { selectStore } from "../../state/slice/appSlice";
import ChatBot from "../../components/chatbot";
import ErrorPage from '../errorPage';
import { QA } from "../../model/IAudioTranscription";

const { Title, Paragraph, Text } = Typography;

interface TranscriptItem {
  time: string;
  person: string;
  content: string;
}

const MyCalls = () => {
  const store = useSelector(selectStore);
  const [data, setData] = useState<any | null>(null); // Adjust the type as per your API response
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [showSlider, setShowSlider] = useState(false); 
  const [isChatBotVisible, setIsChatBotVisible] = useState(false);
  const [response, setResponse] = useState<string | null>(null);
  const [generating, setGenerating] = useState<boolean>(false); 
  const chatBotRef = useRef<HTMLDivElement>(null);
  const [showAudioSlider, setShowAudioSlider] = useState(false); // New state for AudioTranscriptSlider
  const [chatQueries, setChatQueries] = useState([]);

  const fetchData = async () => {
    setError(false);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/get_audio_data/${store.selectedContract.id}/${store.userDetails.username}`
      );
      if (res.data) {
        setData(res.data);
        console.log("Fetched Data:", res.data);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(true);
      setLoading(false);
    }
  };

  const fetchChatQueries = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/top_chat_queries`);
      if (response.data) {
        setChatQueries(response.data.data.map((query: any) => query.query)); // Extracting queries from response
        console.log("Chat Queries:", response.data.data);
      }
    } catch (error) {
      console.error('Error fetching chat queries:', error);
    }
  };

  useEffect(() => {
    if (store.selectedContract.id && store.selectedContract.id !== -1 && store.userDetails.username) {
      fetchData();
      fetchChatQueries(); // Fetch the chat queries
    } else {
      setLoading(false);
      setError(true);
    }
  }, [store.selectedContract, store.userDetails]);

  const handleGenerateResponse = async () => {
    setGenerating(true);
    setShowSlider(true);
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/generate_email/${data?.audio_id}/${store.userDetails.username}/-1`
      );
      // Process the response content to replace \n with line breaks
      const processedResponse = res.data
        .replace(/\n\n/g, '<br/><br/>') // Replace \n\n with two line breaks
        .replace(/\n/g, '<br/>');       // Replace single \n with one line break
      setResponse(processedResponse);
    } catch (error) {
      console.error('Error generating response:', error);
    } finally {
      setGenerating(false);
    }
  };
  
  

  useEffect(() => {
    if (store.selectedContract.id && store.userDetails.username) {
      fetchData();
    } else {
      setLoading(false);
      setError(true);
    }
  }, [store.selectedContract, store.userDetails]);

  if (loading) {
    return (
      <div style={{ textAlign: 'center', padding: '50px' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (error || !data) {
    return <ErrorPage />;
  }

  const questions = data.qa.map((item: QA) => item.question.query);

  // Map transcript data to match the TranscriptItem type
  const mappedTranscriptData: TranscriptItem[] = data.transcript.map((item: any) => ({
    time: item.time, // Map this field accordingly
    person: item.speaker, // Assuming "speaker" is in the API response
    content: item.text, // Assuming "text" is in the API response
  }));

  return (
    <>
      <div className='complete-page'>
        <div className="header-section">
          <Title level={3}>GEN-AI SUPPORT AGENT</Title>
        </div>

        <div className="content-section">
          <div className='audio-details-section'>
            <Row justify="space-between" align="middle">
              <Col>
                {/* <Title level={2}>{data.audioName}</Title> */}
                <Title level={2}>
                  {data.audioName === "tryingupload.wav" 
                    ? "Access Request Inquiry Call" 
                    : data.audioName === "abc" 
                    ? "Support Assistant Call" 
                    : data.audioName === "supportagent2.wav "
                    ? "Call Assistant"
                    : data.audioName}
                </Title>
              </Col>
              <Col>
              <Button
                  type="default"
                  className="action-button"
                  onClick={handleGenerateResponse} // Handle button click
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                    <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829Z"></path>
                  </svg>
                  Generate Response
                </Button>
                <Button 
                  type="default" 
                  className="action-button"
                  onClick={() => setShowAudioSlider(true)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor">
                    <path d="M4.92893 19.0711C3.11929 17.2614 2 14.7614 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22H2L4.92893 19.0711ZM11 6V18H13V6H11ZM7 9V15H9V9H7ZM15 9V15H17V9H15Z"></path>
                  </svg>
                  Audio & Transcript
                </Button>
                {/* <Button type="default" className="action-button-1">...</Button> */}
              </Col>
            </Row>
            <Paragraph className='audio-description'>{data.audio_desc}</Paragraph>
          </div>

          <Divider />

          <div className="details-section">
            <Row>
              <Col span={24}>
                <Title level={3}>Summary</Title>
                <Paragraph className='summary-attributes-container'>
                  <Text className='summary-attributes'>Date of Recording: <p>{data.date_of_rec}</p></Text> | 
                  <Text className='summary-attributes'> Created By: <p>{data.created_by}</p></Text> | 
                  <Text className='summary-attributes'> Created On: <p>{data.date_uploaded}</p></Text>
                </Paragraph>
                <p>{data.summary}</p>
              </Col>
            </Row>
          </div>

          <div className='my-calls-container'>
            <QASection
              qa={data.qa}
              transcript={data.transcript}
              onQuestionClick={(id, index, startTime, resources) => {
                console.log('Question clicked', id, index, startTime, resources);
              }}
            />
          </div>
        </div>
      </div>
      
      {/* Render ResponseSlider if showSlider is true */}
      {showSlider && (
        <EmailResponseSlider
          content={response}
          onClose={() => setShowSlider(false)} 
          loading={generating}
        />
      )}

      {/* Render AudioTranscriptSlider if showAudioSlider is true */}
      {showAudioSlider && (
        <AudioTranscriptSlider
          transcriptData={data.transcript} // Adjusted to match transcript[] type
          audioSrc={data.audioSrc}
          onClose={() => setShowAudioSlider(false)}
          loading={loading} // You can set this according to your logic
        />
      )}

        {!isChatBotVisible && (
            <div className="chatbot-toggle-1" onClick={() => setIsChatBotVisible(!isChatBotVisible)}>
              <span className="hover-text">Smart Bot</span>
              <div className="hexagon-container">
                <svg width="32" height="36" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9606 7.6155C19.7479 6.92473 18.2522 6.92473 17.0395 7.6155L8.23512 12.6303C7.0267 13.3186 6.28265 14.5883 6.28265 15.962V26.0378C6.28265 27.4115 7.0267 28.6812 8.23512 29.3695L17.0395 34.3843C18.2522 35.0751 19.7479 35.0751 20.9606 34.3843L29.765 29.3695C30.9734 28.6812 31.7174 27.4115 31.7174 26.0378V15.962C31.7174 14.5883 30.9734 13.3186 29.765 12.6303L20.9606 7.6155ZM37.587 12.6326C37.587 11.2589 36.8429 9.98924 35.6345 9.30095L20.9606 0.942884C19.7479 0.252113 18.2522 0.252111 17.0395 0.942882L2.36555 9.30094C1.15714 9.98924 0.413086 11.2589 0.413086 12.6326V29.3672C0.413086 30.7409 1.15714 32.0106 2.36555 32.6989L17.0395 41.0569C18.2522 41.7477 19.7479 41.7477 20.9606 41.057L35.6345 32.6989C36.8429 32.0106 37.587 30.7409 37.587 29.3672V12.6326Z" fill="white"/>
                  <path d="M17.0435 19.7605V4.63838L32.254 11.5664C33.703 12.2264 34.5991 13.6865 34.518 15.2554L33.674 31.5868L18.9726 23.0787C17.7774 22.3871 17.0435 21.1247 17.0435 19.7605Z" fill="white"/>
                </svg>
              </div>
              </div>
          )}
          {isChatBotVisible && (
            <div ref={chatBotRef} className="chatbot">
              <ChatBot isVisible={isChatBotVisible} onClose={() => setIsChatBotVisible(false)} questions={chatQueries} setInitialQuery=""/>
            </div>
          )}
    </>
  );
};

export default MyCalls;
